
const GLOBALS = {};

GLOBALS.COMPANY_NAME        = 'Carbon Opus';
GLOBALS.CODENAME            = 'CarbonOpus';
GLOBALS.CODENAME_ABBR       = 'CO';
GLOBALS.CODE_VERSION        = 'v0.1.0';
GLOBALS.BASE_URL            = '';
GLOBALS.APP_ROOT            = '/go';
GLOBALS.CONTACT_EMAIL       = 'info@carbonopus.com';

GLOBALS.SEO = {
  title           : GLOBALS.COMPANY_NAME,
  titleTemplate   : `%s · ${GLOBALS.COMPANY_NAME}`,
  desc            : 'Decentralized Music',
  author          : 'CarbonOpus',
  twitterUsername : '@CarbonOpus',
  url             : GLOBALS.BASE_URL,
  logoUrl         : 'src/images/favicon/android-chrome-192x192.png',
  image           : 'src/images/favicon/android-chrome-192x192.png',
};

// GLOBALS.ROUTES = {
//   home:   { index: 0, id: 'home',   label: 'Home',   route: '/home',   matchedRoutes: [] },
//   search: { index: 1, id: 'search', label: 'Search', route: '/search', matchedRoutes: [] },
// };

GLOBALS.PROTOCOL_PERCENT      = 0.01;
GLOBALS.SALE_PRICE_MIN        = 0.05;
GLOBALS.SALE_PRICE_DEF        = 1.00;
GLOBALS.SALE_PRICE_MAX        = 5.00;
GLOBALS.RESALE_ROYALTY_MIN    = 5;
GLOBALS.RESALE_ROYALTY_DEF    = 20;
GLOBALS.RESALE_ROYALTY_MAX    = 95;

GLOBALS.NFT = {
  THUMBNAIL: {
    MAX_WIDTH: 200,
    QUALITY: 1.0,
  },
};

GLOBALS.BASE_Z_INDEX = 9999999999;

GLOBALS.IS_PRE_LAUNCH = (process.env.GATSBY_IS_PRE_LAUNCH === 'true');

GLOBALS.ENV = process.env.GATSBY_ENV || 'development';
GLOBALS.IS_DEV = (GLOBALS.ENV === 'development');
GLOBALS.IS_STG = (GLOBALS.ENV === 'staging');
GLOBALS.IS_PRD = (GLOBALS.ENV === 'production');
GLOBALS.DEBUG_MODE = !GLOBALS.IS_PRD;

// Carbon Opus Cloud-Functions API
if (GLOBALS.IS_DEV) {
  GLOBALS.CARBONOPUS_API_URL      = `http://localhost:5001/${process.env.GATSBY_FIREBASE_PROJECT_ID}/${process.env.GATSBY_FIREBASE_PROJECT_REGION}/api`;
} else {
  GLOBALS.CARBONOPUS_API_URL      = `https://${process.env.GATSBY_FIREBASE_PROJECT_REGION}-${process.env.GATSBY_FIREBASE_PROJECT_ID}.cloudfunctions.net/api`;
}

// Firebase
GLOBALS.FIREBASE_API_KEY        = process.env.GATSBY_FIREBASE_API_KEY;
GLOBALS.FIREBASE_AUTH_DOMAIN    = process.env.GATSBY_FIREBASE_AUTH_DOMAIN;
GLOBALS.FIREBASE_PROJECT_ID     = process.env.GATSBY_FIREBASE_PROJECT_ID;
GLOBALS.FIREBASE_PROJECT_REGION = process.env.GATSBY_FIREBASE_PROJECT_REGION;
GLOBALS.FIREBASE_STORAGE_BUCKET = process.env.GATSBY_FIREBASE_STORAGE_BUCKET;
GLOBALS.FIREBASE_MSG_SENDER_ID  = process.env.GATSBY_FIREBASE_MSG_SENDER_ID;
GLOBALS.FIREBASE_APP_ID         = process.env.GATSBY_FIREBASE_APP_ID;
GLOBALS.FIREBASE_MEASUREMENT_ID = process.env.GATSBY_FIREBASE_MEASUREMENT_ID;

// NFT Storage
GLOBALS.NFT_STORAGE_TOKEN       = process.env.GATSBY_NFT_STORAGE_API_KEY;

// Etherscan
GLOBALS.ETHERSCAN_RPC_URL  = '';
GLOBALS.ETHERSCAN_APIKEY   = process.env.GATSBY_ETHERSCAN_APIKEY;

// Infura
GLOBALS.INFURA_RPC_URL     = 'https://{chainName}.infura.io/v3/{apiKey}';
GLOBALS.INFURA_APIKEY      = process.env.GATSBY_INFURA_APIKEY;

// Alchemy
GLOBALS.ALCHEMY_APIKEY   = {
  1: process.env.GATSBY_ALCHEMY_APIKEY_MAINNET,
  5: process.env.GATSBY_ALCHEMY_APIKEY_GOERLI,
};

// Default Chain ID
GLOBALS.DEFAULT_CHAIN_ID   = GLOBALS.IS_PROD ? 137 : 5;

GLOBALS.RAY_UNIT              = 1e27;
GLOBALS.ETH_UNIT              = 1e18;
GLOBALS.BP_UNIT               = 1e4;
GLOBALS.ETH_PRECISION         = 18;
GLOBALS.ETH_DISPLAY_PRECISION = 7;

GLOBALS.ADDRESS_ZERO      = '0x0000000000000000000000000000000000000000';
GLOBALS.ADDRESS_DEAD_CODE = '0xDeadc0dedeAdc0DEDeaDc0deDeADc0DedEadc0De';
GLOBALS.BOOLEAN_TRUE_HEX  = '0x0000000000000000000000000000000000000000000000000000000000000001';
GLOBALS.BOOLEAN_FALSE_HEX = '0x0000000000000000000000000000000000000000000000000000000000000000';
GLOBALS.ZERO_HEX          = '0x0000000000000000000000000000000000000000000000000000000000000000';

GLOBALS.EXTERNAL_LINKS = {
  DISCORD: 'https://discord.gg/cdyn3MNa5u',
  TWITTER: 'https://twitter.com/CarbonOpus',
  MEDIUM: 'https://medium.com/charged-particles',
  DOCS: {
    HOME: 'https://docs.carbonopus.com/',
  },
};

GLOBALS.REQUIRED_CURRENCY = {
  // Polygon Testnet (Mumbai)
  '80001': {
    LABEL: 'PMT',
    ADDRESS: '0xf725284D91a88e343D6A096D215b19105e5590Ff', // PaymentToken for Testing
  },
  // Goerli Testnet
  '5': {
    LABEL: 'USDC',
    ADDRESS: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  },
  // Ropsten Testnet
  '3': {
    LABEL: 'USDC',
    ADDRESS: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  },
  // Polygon Mainnet
  '137': {
    LABEL: 'USDC',
    ADDRESS: '',
  },
};

GLOBALS.CONTRACT_ADDRESS = {
  CARBON_OPUS: {
    '80001' : '0x1249583A9CF692fa1Ce42C5BD1a0b8631b06007e',
    '137'   : '',
    '5'     : '0x3D718934b337cf383A6D455686cdD7EB2d28d728',
    '3'     : '0xFf4d6799c43d6bFd8a641d24620A7D25E307eAF6',
  },
  CARBON_ECONOMICS: {
    '80001' : '0xeD1bC3EDAd40f9011f8852cdCfF6E17f093b0d4c',
    '137'   : '',
    '5'     : '0x313Ad9D06dbd31f0C3a3E2D79846B093534fB2c5',
    '3'     : '0x35D5e03F816299Ad2dcCaAD0daEBcCE6D28e8cc1',
  },
  OPUS: {
    '80001' : '0x1249583A9CF692fa1Ce42C5BD1a0b8631b06007e',
    '137'   : '',
    '5'     : '0x8cBbCB79d724e626105D6caA88D6e75A8193Fa6e',
    '3'     : '0x1249583A9CF692fa1Ce42C5BD1a0b8631b06007e',
  },
  USDC: {
    '1': '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', // MAINNET
    '5': '0x1B0f79503f1199eBA50c5Cc3a435BB774e5c836f', // GOERLI (FakeUSDC)
  },
};

GLOBALS.NETWORK_FACTS = {
  '1': {
    blockExplorerUrl: 'https://etherscan.io/',
    publicRpcUrl: '',
    blockExplorerName: 'Etherscan',
    nativeCurrency: {
      name: 'ether',
      symbol: 'ETH',
    },
  },
  '5': {
    blockExplorerUrl: 'https://goerli.etherscan.io/',
    publicRpcUrl: '',
    blockExplorerName: 'Etherscan',
    nativeCurrency: {
      name: 'Goerli ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  '137': {
    blockExplorerUrl: 'https://polygonscan.com/',
    publicRpcUrl: 'https://polygon-rpc.com',
    blockExplorerName: 'Polygonscan',
    nativeCurrency: {
      name: 'Polygon Mainnet',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  '80001': {
    blockExplorerUrl: 'https://mumbai.polygonscan.com/',
    publicRpcUrl: 'https://rpc-mumbai.maticvigil.com',
    blockExplorerName: 'Polygonscan',
    nativeCurrency: {
      name: 'Polygon Testnet',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
};

export { GLOBALS };
