
import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { GLOBALS } from '../app/utils/globals';

function App() {
  useEffect(() => {
    navigate(GLOBALS.APP_ROOT);
  }, []);
  return null;
}

export default App;
